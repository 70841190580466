import { type JSX, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useClient } from "urql";
import getRelativeUrl from "../../shared/getRelativeUrl";
import { query as landingQuery } from "../landing/queries";
import { query as tasksSidebarQuery } from "../tasksSidebar/query";
import ConfettiAward from "./ConfettiAward";
import TaskAward from "./TaskAward";
import UnlockAward from "./UnlockAward";
import type { AnimationType, IData } from "./query";

interface IProps {
    data: IData["flipper"];
}

const ANIMATION_TIMINGS = {
    task: 5000,
    unlock: 4000,
    milestone: 6000,
};

function Flipper({ data }: IProps): JSX.Element {
    const [shownAnimation, setShowAnimation] = useState<AnimationType>(data.animations[0]);
    const navigate = useNavigate();
    const client = useClient();

    useEffect(() => {
        client.query(landingQuery, { id: data.pathId, hurray: true }, { requestPolicy: "network-only" });
        client.query(tasksSidebarQuery, { id: data.pathId }, { requestPolicy: "network-only" });
    }, [data.pathId]);

    useEffect(() => {
        const timeoutIds = [];
        let timeout = ANIMATION_TIMINGS[data.animations[0]];
        for (const animation of data.animations.slice(1)) {
            const timeoutId = window.setTimeout(() => {
                if (animation === "redirect") {
                    navigate(getRelativeUrl(data.redirectUrl));
                } else {
                    setShowAnimation(animation);
                }
            }, timeout);
            timeout += ANIMATION_TIMINGS[animation] ?? 0;
            timeoutIds.push(timeoutId);
        }
        return () => {
            for (const timeoutId of timeoutIds) {
                window.clearTimeout(timeoutId);
            }
        };
    }, [data.redirectUrl, data.animations, navigate]);

    if (shownAnimation === "milestone") {
        return <ConfettiAward data={data} />;
    }
    if (shownAnimation === "task") {
        return <TaskAward data={data} />;
    }
    if (shownAnimation === "unlock") {
        return <UnlockAward data={data} />;
    }
    return <div />;
}

export default Flipper;
