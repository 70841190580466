import { type JSX, memo } from "react";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import useWindowSize from "../../shared/hooks/useWindowSize";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";
import LockedModal, { useLockedModal } from "../LockedModal";
import { useSelectedIds } from "../components/SelectedIds";
import ShowError from "../components/ShowError";
import SendButton from "../messages/SendButton";
import WithTasksSidebar, { SMALL_MOBILE_CUTOFF } from "../tasksSidebar";
import Info from "./Info";
import PathProgress from "./PathProgress";
import RatingButton from "./RatingButton";
import StreetPath from "./StreetPath";
import { type IArgs, type IData, query } from "./queries";
import { Center } from "./stylings";

function getHurray(): boolean {
    const params = new URLSearchParams(window.location.search);
    return params.get("hurray") !== null;
}

function calcMiles(learningPath: IData["learningPath"]): number {
    let miles = learningPath.learningPathItems.reduce((acc, item) => acc + item.earnedMiles, 0);
    miles =
        miles +
        learningPath.customItems.filter((item) => item.complete).reduce((acc, item) => acc + item.earnedMiles, 0);
    return miles;
}

function Landing(): JSX.Element {
    const { width } = useWindowSize();
    const { openLockedModal, ...lockedProps } = useLockedModal();
    const { pathId } = useSelectedIds();
    const hurray = getHurray();
    const [result] = useQuery<IData, IArgs>({
        query,
        variables: { id: pathId, hurray },
    });

    let mainCol = <div />;
    let mainRow = <div />;
    if (result.fetching) {
        mainCol = <Loader />;
    } else if (result.error) {
        mainCol = <ShowError />;
    } else if (result.data.learningPath !== null) {
        const { learningPath } = result.data;
        const miles = calcMiles(learningPath);
        const docPath = ["player/landing/tasks"];
        if (loadJSData().features.custom_tasks) {
            docPath.push("player/landing/tasks-custom");
        }
        const showRating = learningPath.appRatingConfig !== "hide";
        const includeRatingFeedback = learningPath.appRatingConfig === "star_text";
        const ratingPopup =
            hurray &&
            learningPath.showRatingAfterTasks <= learningPath.learningPathItems.filter((item) => item.complete).length;
        if (width < SMALL_MOBILE_CUTOFF) {
            mainCol = (
                <>
                    <StreetPath
                        path={learningPath}
                        avatarSrc={learningPath.avatarSrc}
                        openLockedModal={openLockedModal}
                    />
                    <PathProgress miles={miles} milesGoal={learningPath.goal} />
                </>
            );
            mainRow = (
                <>
                    <Center $direction="column">
                        <SendButton />
                        {showRating && <RatingButton includeFeedback={includeRatingFeedback} popup={ratingPopup} />}
                    </Center>
                    <Info path={learningPath} />
                </>
            );
        } else {
            mainCol = (
                <>
                    {showRating && (
                        <RatingButton
                            includeFeedback={includeRatingFeedback}
                            popup={ratingPopup}
                            style={{ position: "absolute", top: "1rem", right: 0, zIndex: 1 }}
                        />
                    )}
                    <StreetPath
                        path={learningPath}
                        avatarSrc={learningPath.avatarSrc}
                        openLockedModal={openLockedModal}
                    />
                    <PathProgress miles={miles} milesGoal={learningPath.goal} />
                    <Center $direction="row">
                        <SendButton padding="0.5rem 1rem .5325rem 1rem" />
                    </Center>
                    <Info path={learningPath} />
                </>
            );
        }
    }
    return (
        <>
            <WithTasksSidebar openLockedModal={openLockedModal} mainLabel={t("player.landing.tab-path")} big={false}>
                {mainCol}
            </WithTasksSidebar>
            {mainRow}
            <LockedModal {...lockedProps} />
        </>
    );
}

export default memo(Landing);
